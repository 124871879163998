import React, { lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { ROUTES } from "./routes-list";

import UserLayout from "../../layouts/components/UserLayout";
import { ProtectedRoute } from "./protected-route";
import { HeaderProvider } from "../../layouts/components/header/context/context";
import { TourGuideProvider } from "../steps/context";
const Dashboard = lazy(() => import("../../pages/dashboard"));
const Driver = lazy(() => import("../../pages/drivers"));
const Matches = lazy(() => import("../../pages/matches"));

const useRenderRoutes = () =>
  useRoutes([
    {
      path: ROUTES.DASHBOARD,
      element: (
        <ProtectedRoute>
          <HeaderProvider>
            <TourGuideProvider>
              <UserLayout />
            </TourGuideProvider>
          </HeaderProvider>
        </ProtectedRoute>
      ),
      children: [
        { index: true, element: <Dashboard /> },
        { path: ROUTES.DRIVER, element: <Driver /> },
        { path: ROUTES.MATCHES, element: <Matches /> },
        { path: ROUTES.USERSTATS, element: <Dashboard /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/" replace />,
    },
  ]);

export default { useRenderRoutes };
