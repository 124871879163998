import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress, styled } from "@mui/material";

export const ProtectedRoute = ({ children }: any) => {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

  const Progress = styled(CircularProgress)`
    margin: 50vh 0 0 50vw;
  `;

  if (isLoading) {
    return <Progress />;
  }

  if (isAuthenticated) {
    return children;
  }

  loginWithRedirect();

  return <></>;
};
