// ** MUI Imports
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useHeader } from "./context/context";
import { HeaderContextInterface } from "./models";

const DepartmentContent = () => {
  const { departments, departmentId, handleDepartmentChange } =
    useHeader() as HeaderContextInterface;

  const handleChange = (event: SelectChangeEvent) => {
    handleDepartmentChange(event.target.value);
  };
  
  return (
    <FormControl sx={{ m: 1, width: "90%" }} size="small">
      <Select
        labelId="header-department-helper-label"
        id="header-department"
        inputProps={{ "aria-label": "Without label" }}
        value={departments && departments.length > 0 ? departmentId : ""}
        onChange={handleChange}
      >
        {(departments || []).map((dep) => (
          <MenuItem key={dep.id} value={dep.id}>
            {dep.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DepartmentContent;
