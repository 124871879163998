// ** MUI Imports
import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import DepartmentContent from "./departments";
import LocationContent from "./locations";
import WeeklyPeriod from "./weekly-period";
import DatePicker from "./date-picker";
import { useSearchParams } from "react-router-dom";
import { useHeader } from "./context/context";
import { HeaderContextInterface } from "./models";

const FlexContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Header = () => {
  const {  handleWeekChange } =
  useHeader() as HeaderContextInterface;

  return (
    <Container maxWidth="xl">
      <FlexContainer container spacing={4}>
        <Grid item xs={12} lg={2}>
          <LocationContent />
        </Grid>
        <Grid item xs={12} lg={2}>
          <DepartmentContent />
        </Grid>
        <Grid item xs={6} lg={1}>
          <Button variant="outlined">
            <FileDownloadIcon />
          </Button>
        </Grid>

        <Grid item xs={6} lg={1}>
          <Button variant="outlined">
            <LocalPrintshopIcon />
          </Button>
        </Grid>

        <Grid item xs={6} lg={1}>
          <DatePicker />
        </Grid>
        <Grid item xs={12} lg={5}>
          <Grid container spacing={2}>
            <FlexContainer item xs={12} lg={3}>
              <FormLabel><Button style={ {color:"#333"}} onClick={() => {
                    const currentDate: any = new Date();
                    const startDate: any = new Date(currentDate.getFullYear(), 0, 1);
                    var days = Math.floor((currentDate - startDate) /
                        (24 * 60 * 60 * 1000));
                        handleWeekChange(Math.ceil(days / 7).toString())
              }}>THIS WEEK</Button></FormLabel>
            </FlexContainer>
            <FlexContainer item xs={12} lg={6}>
              <WeeklyPeriod />
            </FlexContainer>
          </Grid>
        </Grid>
      </FlexContainer>
    </Container>
  );
};

export default Header;
