import React, { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { radialBarConfig } from "./chart-config";
import { thresholds } from "../../utils/color-threshold";
import { isArray } from "lodash";

interface IRadialBarChart {
  series?: ApexOptions["series"];
  labels: string[];
  dataLabels?: any;
  legend?: boolean;
  disableRga?: boolean;
  colours?: string[];
}

const RadialBarChart = (props: IRadialBarChart) => {
  const { series, labels, dataLabels, legend, disableRga, colours } = props;
  const theme = useTheme() as any;
  const colorBar =
    series &&
    series?.length === 1 &&
    typeof series[0] === "number" &&
    !disableRga &&
    thresholds(series[0], 1)

  const config: ApexOptions = radialBarConfig(theme);
  // ** Hook
  const options: ApexOptions = useMemo(() => {
    return {
      ...config,
      colors: colorBar ? [colorBar] :  (colours ? colours : theme.palette.charts.primaryShades),
      plotOptions: {
        ...config.plotOptions,
        radialBar: {
          ...config.plotOptions?.radialBar,
          dataLabels: {
            ...config.plotOptions?.radialBar?.dataLabels,
            ...dataLabels,
          },
        },
      },
      legend: legend
        ? {
            position: "bottom",
            show: true,
          }
        : {
            show: false,
          },
      labels: labels,
      chart: {
        fontFamily: theme?.typography?.fontFamily,
      },
    };
  }, [colorBar]);

  return (
    <Chart type="radialBar" width={"100%"} series={series} options={options} />
  );
};

export { RadialBarChart };
