// ** MUI Imports
import { useLocation, useSearchParams } from "react-router-dom";

// ** Util Import

export type UseBgColorType = {
  [key: string]: {
    color: string;
    backgroundColor: string;
  };
};

const getPageLink = (page: any, locationId?: string) => {
  // ** Hooks
  const [searchParams] = useSearchParams();
  const { search } = useLocation();

  if (!locationId) return "";

  let newQueryString = "";

  if (
    searchParams.get("locationId") == "all" &&
    page.indexOf("dashboard") == -1
  ) {
    newQueryString = "?locationId=" + locationId;

    searchParams.forEach((v, k) => {
      if (k !== "locationId") newQueryString += `&${k}=${v}`;
    });

    return `${page}${newQueryString}`;
  }

  return `${page}${search}`;
};

export default getPageLink;
