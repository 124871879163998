// ** MUI Imports
import React from "react";
import { useTheme } from "@mui/material";
import { useHeader } from "./context/context";
import { HeaderContextInterface } from "./models";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { hexToRGBA } from "../../../core/utils/hex-to-rgba";

const DatePicker = () => {
  const { handleDateChange, weekDate } = useHeader() as HeaderContextInterface;
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        inputFormat="MM/DD/YYYY"
        value={weekDate}
        disableMaskedInput={true}
        onChange={(date: Dayjs | null) => handleDateChange(date)}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              "MuiInputBase-root-MuiOutlinedInput-root": { width: 0 },
              ".MuiInputBase-input": { padding: 0, width: 0 },
              ".MuiOutlinedInput-notchedOutline": {
                width: 0,
                border: "none",
                padding: 0,
              },
              ".MuiButtonBase-root": {
                border: `1px solid ${hexToRGBA(
                  theme.palette.primary.main,
                  0.64
                )}`,
                color: `${hexToRGBA(theme.palette.primary.main, 1)}`,
                width: "68px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
              },
              ".MuiInputAdornment-root": {
                marginLeft: 0,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
