import * as React from "react";
import { SwlProgressBar } from "./";
import { getPercent } from "../../../utils";
import { QuarterlyReport as IQuarterlyReport } from "../../../models";
import { thresholds } from "../../utils/color-threshold";
import { hexToRGBA } from "../../utils/hex-to-rgba";
import { useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";

interface IProgressBar {
  covered: number;
  total: number;
  titleText?: string;
  height?: number;
  totalTooltipText?: string;
  href?: string;
  shownPercentage?: boolean;
  showRga?: boolean;
}

const ProgressBar = ({
  covered,
  total,
  titleText,
  height,
  totalTooltipText: totalTooltipText,
  href,
  showRga,
}: IProgressBar) => {
  const theme = useTheme();

  const isTotalZero = total === 0;
  return (
    <SwlProgressBar
      startPoint={covered}
      total={
        totalTooltipText ? `${total - covered} ${totalTooltipText}` : total
      }
      coveredProgressBar={isTotalZero ? 0 : getPercent(covered, total)}
      completed={isTotalZero ? false : total == covered}
      titleText={titleText}
      height={height}
      href={href}
      containerBgColor={hexToRGBA(theme.palette.grey[400], 1)}
      bgColor={
        showRga
          ? isTotalZero
            ? ""
            : thresholds(getPercent(covered, total), 1)
          : ""
      }
    />
  );
};

export function QuarterlyReport(props: IQuarterlyReport) {
  const {
    contracts,
    flex,
    skills,
    height,
    totalTooltipText: totalTooltipText,
    shownPercentage,
    recruitment,
    showOverall,
    showRga,
    completedMatches,
    totalMatches,
  } = props;

  const [searchParams] = useSearchParams();
  let matchesLink = "/matches?";

  searchParams.forEach((value, key) => {
    if (key != "filter") matchesLink += `${key}=${value}&`;
  });

  return (
    <React.Fragment>
      {showOverall && (
        <ProgressBar
          covered={completedMatches}
          total={totalMatches}
          titleText={"OVERALL"}
          height={height}
          totalTooltipText={totalTooltipText}
          href={`/matches${document.location.search}`}
          shownPercentage={shownPercentage}
          showRga={true}
        />
      )}
      <ProgressBar
        covered={contracts.covered}
        total={contracts.total}
        titleText={"CONTRACTS"}
        height={height}
        totalTooltipText={totalTooltipText}
        href={`${matchesLink}filter=contracts`}
        shownPercentage={shownPercentage}
        showRga={showRga}
      />
      <ProgressBar
        covered={flex.covered}
        total={flex.total}
        titleText={"FLEX"}
        height={height}
        totalTooltipText={totalTooltipText}
        href={`${matchesLink}filter=flex`}
        shownPercentage={shownPercentage}
        showRga={showRga}
      />
      <ProgressBar
        covered={skills.covered}
        total={skills.total}
        titleText={"SKILLS"}
        height={height}
        totalTooltipText={totalTooltipText}
        href={`${matchesLink}filter=skills`}
        shownPercentage={shownPercentage}
        showRga={showRga}
      />
      <ProgressBar
        covered={recruitment?.covered || 0}
        total={recruitment?.total || 0}
        titleText={"RECRUITMENT"}
        height={height}
        totalTooltipText={totalTooltipText}
        href={`${matchesLink}filter=recruitment`}
        shownPercentage={shownPercentage}
        showRga={showRga}
      />
    </React.Fragment>
  );
}
