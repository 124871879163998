import * as React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

interface TitleProps {
  children?: React.ReactNode;
}

export function SubTitle(props: TitleProps) {
  return <Text variant="subtitle1">{props.children}</Text>;
}

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: "bold",
}));
