import * as React from "react";
import {
  ListItemButton,
  Button,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import styled from "styled-components";
import navigation from "../../../navigation/vertical";
import { ColorForm } from "./color-form";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Menu, Modal } from "../../../core/components";
import getPageLink from "../../../core/components/utils/getPageLink";
import { useGetProfile } from "../header/use-get-profile";
import TourIcon from "@mui/icons-material/Tour";
import { useTour } from "@reactour/tour";
import {
  TourGuideContextInterface,
  useTourGuide,
} from "../../../navigation/steps/context";
import { delay } from "lodash";

interface SideBarProps {
  toggleDrawer: () => void;
}
const SideBar = ({ toggleDrawer }: SideBarProps) => {
  const { logout, isLoading, user } = useAuth0();

  const { data: userProfile, isLoading: isLoadingProfile } = useGetProfile(
    user!.cmUserId
  );

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openColor, setOpenColor] = React.useState(false);
  const openMenu = Boolean(anchorEl);

  const { setIsOpen } = useTour();
  const { navigateIntoView } = useTourGuide() as TourGuideContextInterface;

  const handleClickItem = () => {
    !isDesktop && toggleDrawer();
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    event && event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenColor(false);
  };

  const handleSelectMenu = (event: string) => {
    handleClose();
    setOpenColor(true);
  };

  const getClassName = (text: string) => {
    switch (text) {
      case "Dashboard":
        return "first-step";
      case "Workload Inputs":
        return "workload-input-step-1";
      case "Matches":
        return "match-step-1";
      default:
        return "";
    }
  };
  return (
    <SidebarContent>
      {navigation().map((nav: any) => (
        <Link
          to={`${getPageLink(
            nav.path,
            !isLoadingProfile ? userProfile?.locations[0].id : ""
          )}`}
          key={nav.path}
          style={{
            textDecoration: "none",
            color: "#000000",
          }}
          onClick={handleClickItem}
        >
          <ListItemButton>
            <ListItemIcon>{nav.icon}</ListItemIcon>
            <ListItemText
              primary={nav.title}
              className={getClassName(nav.title)}
            />
          </ListItemButton>
        </Link>
      ))}
      <TourContainer>
        <ListItemButton
          onClick={() => {
            navigateIntoView();
            delay(() => {
              setIsOpen(true);
            }, 300);
          }}
        >
          <ListItemIcon>
            <TourIcon />
          </ListItemIcon>
          <ListItemText primary="Take Tour" />
        </ListItemButton>
      </TourContainer>
      {!isLoading && user && (
        <AvatarContainer>
          <AvatarLabel>
            <Avatar
              style={{ marginRight: "14px" }}
              alt={user.name}
              src={user.picture}
              onClick={handleClickMenu}
            />
            <AvatarName variant="body2" noWrap>
              {user.name}
            </AvatarName>
          </AvatarLabel>
          <Button
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            <LogoutIcon />
          </Button>
        </AvatarContainer>
      )}
      <Menu
        handleSelectMenu={handleSelectMenu}
        handleCloseMenu={handleClose}
        open={openMenu}
        menuOptions={menuOptions}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      />
      {openColor && (
        <Modal
          open={openColor}
          setOpen={setOpenColor}
          handleClose={handleClose}
        >
          <ColorForm handleClose={handleClose} />
        </Modal>
      )}
    </SidebarContent>
  );
};

const TourContainer = styled.div`
  position: fixed;
  bottom: 50px;
  margin-bottom: 14px;
  width: 240px;
`;
const AvatarContainer = styled.div`
  display: flex;
  width: 240px;
  justify-content: align-self;
  bottom: 50px;
  margin-bottom: 14px;
  padding: 0.5em;
  position: fixed;
  bottom: 0;

  & > * {
    margin: 4px;
  }
`;

const AvatarLabel = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const AvatarName = styled(Typography)`
  max-width: 100px;
`;

const SidebarContent = styled.div`
  display: block;
`;

export default SideBar;

const menuOptions = [
  {
    name: "Theme Colors",
    value: "colors",
  },
];
