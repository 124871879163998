import * as React from "react";
import { styled, useTheme, Box } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
    padding: 10,
  },
}));

export function ClearMatchToolTip({ message, children }: any) {
  const theme = useTheme();

  return (
    <HtmlTooltip
      title={
        <Box display={"flex"}>
          <Typography
            color={theme.palette.primary.contrastText}
            variant="body2"
          >
            {message}
            <Box display={"flex"} justifyContent="flex-end" marginTop={2}></Box>
          </Typography>
        </Box>
      }
    >
      {children}
    </HtmlTooltip>
  );
}
