// ** MUI Imports
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useHeader } from "./context/context";
import { HeaderContextInterface } from "./models";

const LocationContent = () => {
  const { locations, locationId, handleLocationChange } =
    useHeader() as HeaderContextInterface;

  const handleChange = (event: SelectChangeEvent) => {
    handleLocationChange(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, width: "90%" }} size="small">
      <Select
        labelId="header-location-helper-label"
        id="header-location"
        inputProps={{ "aria-label": "Without label" }}
        value={locations ? locationId : ""}
        onChange={handleChange}
      >
        <MenuItem key={'all'} value='all'>
          Location
        </MenuItem>
        {(locations || []).map((loc) => (
          <MenuItem key={loc.id} value={loc.id}>
            {loc.address}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LocationContent;
