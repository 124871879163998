import React, { useMemo, FC } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Button, ButtonGroup, Container } from "@mui/material";
import { useGetProfile } from "../header/use-get-profile";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";
import { Location } from "../header/models/types";
import { hexToRGBA } from "../../../core/utils/hex-to-rgba";

export const QuarterSelection: FC = () => {
  const { user } = useAuth0();
  const { data } = useGetProfile(user!.cmUserId);
  const [searchParams, setSearchParams] = useSearchParams();
  const departmentId = searchParams.get("departmentId") || "";
  const weekNumber = searchParams.get("weekNumber") || "";
  const locationId = searchParams.get("locationId") || "";
  const quarter = searchParams.get("quarter") || "";
  const year = searchParams.get("year") || "";

  interface QuarterSelectionSetting {
    lastYear: number;
    lastYearDisabled: boolean;
    nextYear: number;
    nextYearDisabled: boolean;
    quarters: string[];
    currentQuarter: number;
    currentYear: number;
  }

  const getSingleItemFromArray = (array: any[], filter: any): any => {
    let arr = array.filter(filter);

    if (arr.length == 1) return arr.pop();

    return null;
  };

  const {
    lastYear,
    lastYearDisabled,
    nextYear,
    nextYearDisabled,
    quarters,
    currentYear,
    currentQuarter,
  } = useMemo(() => {
    let currentYear = parseInt(year);
    let currentQuarter = parseInt(quarter);

    if (isNaN(currentYear)) currentYear = new Date().getFullYear();

    if (isNaN(currentQuarter)) currentQuarter = 1;

    const settings: QuarterSelectionSetting = {
      lastYear: currentYear - 1,
      lastYearDisabled: true,
      nextYear: currentYear + 1,
      nextYearDisabled: true,
      quarters: [],
      currentYear: currentYear,
      currentQuarter: currentQuarter,
    };

    if (!data || !data.locations) {
      return settings;
    }

    let location: Location = getSingleItemFromArray(
      data.locations,
      (l: Location) => l.id.toString() === locationId
    );

    if (location == null) {
      // Area manager, so pull first location.
      location = data.locations[0];
    }

    const yearIndex = location.history.years.indexOf(currentYear);

    if (yearIndex == -1) return settings;

    const history = location.history;

    settings.lastYear =
      location.history.years[yearIndex - 1] || currentYear - 1;
    settings.lastYearDisabled = history.years[yearIndex - 1] === undefined;
    settings.nextYear = history.years[yearIndex + 1] || currentYear + 1;
    settings.nextYearDisabled = history.years[yearIndex + 1] === undefined;
    settings.quarters = [];

    if (yearIndex !== 0 && yearIndex !== history.years.length - 1) {
      settings.quarters = ["Q1", "Q2", "Q3", "Q4"];
    } else if (yearIndex == history.years.length - 1) {
      settings.quarters = Array.from(
        { length: history.lastAvailableQuarter },
        (_, index) => `Q${index + 1}`
      );
    } else if (yearIndex == 0) {
      settings.quarters = Array.from(
        { length: 4 - (history.firstAvailableQuarter - 1) },
        (_, index) => `Q${history.firstAvailableQuarter + index}`
      );
    }

    if (
      settings.quarters.filter((q) => q.substring(1) == quarter).length == 0
    ) {
      currentQuarter = +settings.quarters[0].substring(1);
    }

    return settings;
  }, [quarter, year, locationId, data]);

  const updateQueryString = (
    yearToSet: string,
    quarterToSet: string,
    weekNumberToSet?: string
  ) => {
    setSearchParams({
      year: yearToSet,
      locationId,
      weekNumber: weekNumberToSet || weekNumber,
      quarter: quarterToSet,
      departmentId,
    });
  };

  const setQuarter = (yearToSet: number, quarterToSet: number) => {
    let currentWeeks = data?.weeklyPeriods.filter(
      (w) => w.year == year && w.quarter == quarter.toString()
    );

    // find position of active week in the quarter.
    let weekEntry = currentWeeks!.filter((s) => s.weekNumber == weekNumber)[0];

    let positionInQuarter: number = currentWeeks?.indexOf(weekEntry) || 0;

    let searched = data?.weeklyPeriods.filter(
      (w) => w.year == year && w.quarter == quarterToSet.toString()
    );

    if (searched && searched.length > 0) {
      updateQueryString(
        yearToSet.toString(),
        quarterToSet.toString(),
        searched[positionInQuarter].weekNumber
      );
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container justifyContent={"center"} margin={"1rem 0 2rem"}>
        <Grid item xs={12}>
          <QuarterButtons
            variant="contained"
            aria-label="outlined primary button group"
          >
            <QuarterButton
              style={{ flexDirection: "row" }}
              disabled={lastYearDisabled}
              onClick={() => {
                setQuarter(lastYear, 4);
              }}
            >
              <ArrowLeft /> {lastYear}
            </QuarterButton>
            {quarters.map((q: string, index) => {
              if (q.substring(1) === currentQuarter.toString())
                return (
                  <SelectedQuarterButton
                    key={q}
                    className={`quarter-${index + 1}`}
                  >
                    {q}
                    <small>{year}</small>
                  </SelectedQuarterButton>
                );

              return (
                <QuarterButton
                  onClick={() => {
                    setQuarter(currentYear, +q.substring(1));
                  }}
                  className={`quarter-${index + 1}`}
                  key={q}
                >
                  {q}
                  <small>{year}</small>
                </QuarterButton>
              );
            })}
            <QuarterButton
              style={{ flexDirection: "row" }}
              disabled={nextYearDisabled}
              onClick={() => {
                setQuarter(nextYear, 1);
              }}
            >
              {nextYear} <ArrowRight />
            </QuarterButton>
          </QuarterButtons>
        </Grid>
      </Grid>
    </Container>
  );
};

const QuarterButtons = styled(ButtonGroup)(() => ({
  width: "100%",
}));

const QuarterButton = styled(Button)(({ theme }: any) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "1rem",
  background: `${hexToRGBA(theme.palette.primary.main, 0.8)}`,
}));

const SelectedQuarterButton = styled(QuarterButton)(({ theme }: any) => ({
  background: `${hexToRGBA(theme.palette.primary.main, 1)}`,
}));
