import { useTheme } from "@mui/material/styles";
import { hexToRGBA } from "./hex-to-rgba";
import { ExtendedThemeOptions } from "../layouts/types";
export const thresholds = (percentage: number, opacity = 1, theme? : ExtendedThemeOptions | undefined) => {
  if(!theme)
    theme = useTheme() as ExtendedThemeOptions;

  let color = theme?.palette?.rga?.red;
  if (percentage >= 85) {
    color = theme.palette?.rga?.green;
  } else if (percentage >= 70) {
    color = theme.palette?.rga?.amber;
  }
  return color && hexToRGBA(color, opacity);
};
