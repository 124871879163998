import React, { useMemo, useState } from "react";
import { createGenericContext } from "../../utils";
import { TourProvider } from "@reactour/tour";
import { dashboardSteps, matchesSteps, workloadInputSteps } from "./list";
import { HeaderContextInterface } from "../../layouts/components/header/models";
import { useHeader } from "../../layouts/components/header/context/context";
import { delay, map } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";

export interface TourGuideContextInterface {
  user: number;
  sortType: string;
  onSortingChange: (e: string) => void;
  onUserChange: (e: number) => void;
  tab: number;
  navigateIntoView: () => void;
}
const [useTourGuide, TourGuideContextProvider] =
  createGenericContext<TourGuideContextInterface | undefined>();

const workloadInputsPath = "/workload-inputs";
const matchInputsPath = "/matches";
const TourGuideProvider = (props: any) => {
  const [sortType, setSortType] = useState("flex");
  const [user, setUser] = useState<number>(-1);
  const [tab, setTab] = useState(0);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { handleWeekChange, handleQuarterChange, weekNumber } =
    useHeader() as HeaderContextInterface;

  const steps = useMemo(
    () =>
      dashboardSteps
        .concat(workloadInputSteps)
        .concat(matchesSteps)
        .map((x) => ({
          ...x,
          content: (
            <Box maxHeight="75vh" overflow="auto">
              {x.content}
            </Box>
          ),
        })),
    []
  );
  const matchesCallback = (index: number) => {
    const baseIndex = 17;
    switch (index) {
      case 1:
      case 2:
      case 3:
      case 4:
        handleQuarterChange(index.toString());
        setStep(index + baseIndex);
        break;
      case 6:
        if (weekNumber !== "15") {
          handleWeekChange("15");
          delay(() => {
            setStep(index + baseIndex);
          }, 500);
        } else {
          setStep(index + baseIndex);
        }
        break;
      case 7:
      case 8:
      case 9:
        if (
          sortType !== "flex" ||
          user !== 2018 ||
          weekNumber !== "15" ||
          tab !== 0
        ) {
          setSortType("flex");
          setUser(2018);
          handleWeekChange("15");
          setTab(0);
          delay(() => {
            setStep(index + baseIndex);
          }, 1500);
        } else {
          setStep(index + baseIndex);
        }
        break;
      case 10:
        if (
          sortType !== "flex" ||
          user !== 2018 ||
          tab !== 2 ||
          weekNumber !== "15"
        ) {
          setSortType("flex");
          setUser(2018);
          setTab(2);
          handleWeekChange("15");
          delay(() => {
            setStep(index + baseIndex);
          }, 500);
        } else {
          setStep(index + baseIndex);
        }
        break;
      case 11:
        if (
          sortType !== "flex" ||
          user !== 2018 ||
          tab !== 0 ||
          weekNumber !== "15"
        ) {
          setSortType("flex");
          setUser(2018);
          setTab(0);
          handleWeekChange("15");
          delay(() => {
            setStep(index + baseIndex);
          }, 500);
        } else {
          setStep(index + baseIndex);
        }
        break;
      case 12:
      case 13:
      case 14:
        if (
          sortType !== "contracts" ||
          user !== 2014 ||
          tab !== 0 ||
          weekNumber !== "18"
        ) {
          handleWeekChange("18");
          setSortType("contracts");
          setUser(2014);
          setTab(0);
          delay(() => {
            setStep(index + baseIndex);
          }, 1500);
        } else {
          setStep(index + baseIndex);
        }
        break;
      case 15:
        if (
          sortType !== "contracts" ||
          user !== 2014 ||
          tab !== 2 ||
          weekNumber !== "18"
        ) {
          handleWeekChange("18");
          setSortType("contracts");
          setUser(2014);
          setTab(2);
          delay(() => {
            setStep(index + baseIndex);
          }, 500);
        } else {
          setStep(index + baseIndex);
        }
        break;
      case 16:
        if (
          sortType !== "contracts" ||
          user !== 2014 ||
          tab !== 0 ||
          weekNumber !== "18"
        ) {
          handleWeekChange("18");
          setSortType("contracts");
          setUser(2014);
          setTab(0);
          delay(() => {
            setStep(index + baseIndex);
          }, 500);
        } else {
          setStep(index + baseIndex);
        }
        break;
      case 18:
      case 19:
      case 20:
        if (
          sortType !== "skills" ||
          user !== 2009 ||
          tab !== 0 ||
          weekNumber !== "18"
        ) {
          handleWeekChange("18");
          setSortType("skills");
          setUser(2009);
          setTab(0);
          delay(() => {
            setStep(index + baseIndex);
          }, 1500);
        } else {
          setStep(index + baseIndex);
        }
        break;
      case 21:
        if (
          sortType !== "skills" ||
          user !== 2009 ||
          tab !== 2 ||
          weekNumber !== "18"
        ) {
          handleWeekChange("18");
          setSortType("skills");
          setUser(2009);
          setTab(2);
          delay(() => {
            setStep(index + baseIndex);
          }, 500);
        } else {
          setStep(index + baseIndex);
        }
        break;
      case 22:
        if (
          sortType !== "skills" ||
          user !== 2009 ||
          tab !== 0 ||
          weekNumber !== "18"
        ) {
          handleWeekChange("18");
          setSortType("skills");
          setUser(2009);
          setTab(0);
          delay(() => {
            setStep(index + baseIndex);
          }, 500);
        } else {
          setStep(index + baseIndex);
        }
        break;
      case 23:
      case 24:
      case 25:
        if (
          sortType !== "recruitment" ||
          user !== 2001 ||
          tab !== 0 ||
          weekNumber !== "18"
        ) {
          handleWeekChange("18");
          setSortType("recruitment");
          setUser(2003);
          setTab(0);
          delay(() => {
            setStep(index + baseIndex);
          }, 1500);
        } else {
          setStep(index + baseIndex);
        }
        break;
      case 26:
        if (
          sortType !== "recruitment" ||
          user !== 2001 ||
          tab !== 2 ||
          weekNumber !== "18"
        ) {
          handleWeekChange("18");
          setSortType("recruitment");
          setUser(2003);
          setTab(2);
          delay(() => {
            setStep(index + baseIndex);
          }, 500);
        } else {
          setStep(index + baseIndex);
        }
        break;
      case 27:
        if (
          sortType !== "recruitment" ||
          user !== 2001 ||
          tab !== 0 ||
          weekNumber !== "18"
        ) {
          handleWeekChange("18");
          setSortType("recruitment");
          setUser(2003);
          setTab(0);
          delay(() => {
            setStep(index + baseIndex);
          }, 500);
        } else {
          setStep(index + baseIndex);
        }
        break;
      default:
        setStep(index + baseIndex);
        break;
    }
  };
  const workloadCallback = (index: number) => {
    setStep(index + 9);
  };
  const dashboardCallback = (index: number) => {
    switch (index) {
      case 0:
      case 1:
      case 2:
      case 3:
        if (weekNumber !== "15") {
          handleWeekChange("15");
          delay(() => {
            setStep(index);
          }, 500);
        } else {
          setStep(index);
        }
        break;
      case 4:
      case 5:
      case 6:
      case 7:
        handleQuarterChange((index - 3).toString());
        delay(() => {
          setStep(index);
        }, 50);
        break;
      case 8:
        setStep(index);
        navigate(
          "/workload-inputs?locationId=868-A2&departmentId=&weekNumber=18&quarter=4&year=2023"
        );
        break;
      default:
        setStep(index);
        break;
    }
  };
  const setCurrentStep = (step: any) => {
    if (step <= 9) {
      if (location.pathname !== "/") {
        navigate("/" + location.search);
        dashboardCallback(step);
      } else {
        dashboardCallback(step);
      }
    } else if (step < 17) {
      if (location.pathname !== workloadInputsPath) {
        navigate(workloadInputsPath + location.search);
        workloadCallback(step - 9);
      } else {
        workloadCallback(step - 9);
      }
    } else {
      if (location.pathname !== matchInputsPath) {
        navigate(matchInputsPath + location.search);
        matchesCallback(step - 17);
      } else {
        matchesCallback(step - 17);
      }
    }
  };

  const navigateIntoView = () => {
    if (step <= 9) {
      if (location.pathname !== "/") {
        navigate("/" + location.search);
      }
    } else if (step < 17) {
      if (location.pathname !== workloadInputsPath) {
        navigate(workloadInputsPath + location.search);
      }
    } else {
      if (location.pathname !== matchInputsPath) {
        navigate(matchInputsPath + location.search);
      }
    }
  };

  return (
    <TourGuideContextProvider
      value={{
        navigateIntoView,
        tab,
        user,
        sortType,
        onSortingChange: (e: string) => setSortType(e),
        onUserChange: (e: number) => setUser(e),
      }}
    >
      <TourProvider
        steps={steps}
        currentStep={step}
        setCurrentStep={setCurrentStep}
        onClickMask={() => {}}
        defaultOpen={false}
        onClickClose={(props) => {
          props.setIsOpen(false);
        }}
        afterOpen={() => {
          if (location.pathname !== "/") {
            navigate("/" + location.search);
            dashboardCallback(step);
          }
        }}
        disableDotsNavigation={true}
        showDots={false}
        showCloseButton={true}
      >
        {props.children}
      </TourProvider>
    </TourGuideContextProvider>
  );
};

export { useTourGuide, TourGuideProvider };
