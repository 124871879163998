import { ApexOptions } from "apexcharts";
import { hexToRGBA } from "../../utils/hex-to-rgba";

export const radialBarConfig = (theme: any) => {
  const config: ApexOptions = {
    chart: {
      sparkline: { enabled: true },
    },
    stroke: { lineCap: "round" },
    colors: [`${hexToRGBA(theme.palette.primary.dark, 1)}`],
    plotOptions: {
      radialBar: {
        hollow: { size: "60%" },
        track: {
           background: `${hexToRGBA(theme.palette.grey[300], 1)}`,
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            color: "#888",
            fontSize: "9px",
            offsetY: -5,
          },
          value: {
            offsetY: 5,
            fontWeight: 600,
            fontSize: "0.8rem",
            color: "#41435C",
          },
        },
      },
    },
    grid: {
      padding: {
        bottom: -5,
      },
    },
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
  };
  return config;
};
