// ** React Imports
import React, { ReactNode, useEffect, useState } from "react";

// ** MUI Imports
import {
  styled,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";

import { Outlet, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import HorizontalAppBar from "./horizontal/app-bar";
import VerticalSideBar from "./vertical/side-bar";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  IconButton,
  Box,
  Drawer,
  useMediaQuery,
  Divider,
  List,
  Toolbar,
} from "@mui/material";
import { ChevronLeft, Menu, ChevronRight } from "@mui/icons-material";
import { QuarterSelection } from "./quarter-selection";
import { ClearMatchToolTip } from "../../core/components/clearmatch-tooltip";
import { useHeader } from "./header/context/context";
import { HeaderContextInterface } from "./header/models";

// ** Layout Imports
// !Do not remove this Layout import

interface Props {
  children: ReactNode;
  contentHeightFixed?: boolean;
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  leftopen?: number;
  rightopen?: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, rightopen, leftopen }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(leftopen && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(rightopen && {
    marginRight: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const mdTheme = createTheme();

const UserLayout = () => {
  // ** Hooks
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));
  const [open, setOpen] = React.useState(isDesktop);
  const [openDrawerRight, setOpenDrawerRight] = React.useState(false);
  const toggleDrawer = () => {
    setOpenDrawerRight(false);

    setOpen(!open);
  };
  const toggleDrawerRight = () => {
    setOpen(false);
    setOpenDrawerRight(!openDrawerRight);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        leftopen={open ? 1 : 0}
        rightopen={openDrawerRight ? 1 : 0}
        style={{ background: "white" }}
      >
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
            paddingRight: "4px !important",
          }}
        >
          <ClearMatchToolTip message="Show Menu">
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <Menu />
            </IconButton>
          </ClearMatchToolTip>

          {isDesktop ? (
            <HorizontalAppBar />
          ) : (
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={toggleDrawerRight}
              sx={{
                marginLeft: "auto",
                ...(openDrawerRight && { display: "none" }),
              }}
            >
              <Menu />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <DrawerPanel variant="persistent" open={open} toggleDrawer={toggleDrawer}>
        <List component="nav">
          <VerticalSideBar toggleDrawer={toggleDrawer} />
        </List>
      </DrawerPanel>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          width: "100vw",
        }}
      >
        <Toolbar />
        <QuarterSelection />
        <Outlet />
      </Box>

      <DrawerPanel
        variant="persistent"
        anchor="right"
        open={openDrawerRight}
        toggleDrawer={toggleDrawerRight}
      >
        <HorizontalAppBar />
      </DrawerPanel>
    </Box>
  );
};

export default UserLayout;

const SwlDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  overflow: "hidden",
  position: "absolute",
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
  },
  [theme.breakpoints.up("lg")]: {
    position: "relative",
  },
}));
const DrawerPanel = ({
  open,
  toggleDrawer,
  variant = "permanent",
  anchor = "left",
  children,
}: any) => {
  const theme = useTheme();

  return (
    <SwlDrawer
      variant={variant}
      open={open}
      anchor={anchor}
      sx={{
        ...(!open && { display: "none" }),
      }}
    >
      <DrawerHeader
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "left",
        }}
      >
        <ClearMatchToolTip message={"Full screen"}>
          <IconButton onClick={toggleDrawer}>
            {theme.direction === "rtl" ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </ClearMatchToolTip>

        <img
          src="/images/sainsburys-argos.png"
          style={{ maxWidth: "100%", display: "block" }}
        />
      </DrawerHeader>
      <Divider />
      {children}
    </SwlDrawer>
  );
};
