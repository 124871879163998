// ** MUI Imports
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { ApexOptions } from "apexcharts";
import ReactApexcharts from "../react-apexcharts";

interface ILineChart {
  series?: ApexOptions["series"];
  height: string;
  dataLabels?: any;
  strokeWidths?: number[];
  title?: string;
  categories: string[];
  dashArray?: boolean;
  showLegend?: boolean;
  colours?: string[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  titleColor?: string;
}

const LineChart = (props: ILineChart) => {
  const theme = useTheme() as any;

  const options: ApexOptions = {
    colors: props.colours ? props.colours : theme.palette.charts.primaryShades,
    title: {
      text: props.title,
      style: {
        color: props.titleColor ?? "",
        fontSize: "16px",
      },
    },
    chart: {
      parentHeightOffset: 0,
      zoom: { enabled: false },
      toolbar: { show: false },
      fontFamily: theme.typography.fontFamily,
    },
    legend: {
      show: props.showLegend === undefined || props.showLegend,
    },
    stroke: {
      width: props.strokeWidths || [4],
      curve: "straight",
      dashArray: props.dashArray ? [0, 8, 5] : [],
    },
    dataLabels: props.dataLabels || {
      enabled: false,
    },
    markers: {
      strokeWidth: 7,
      strokeOpacity: 1,
    },
    grid: {
      padding: { top: -10 },
      xaxis: {
        lines: { show: false },
      },
      yaxis: {
        lines: { show: false },
      },
    },

    yaxis: {
      labels: {
        style: { colors: theme.palette.text.disabled },
      },
      show: true,
      title: {
        text: props.yAxisTitle,
      },
    },
    xaxis: {
      title: {},
      axisBorder: { show: false },
      axisTicks: { color: theme.palette.divider },
      crosshairs: {
        stroke: { color: theme.palette.divider },
      },
      labels: {
        style: { colors: theme.palette.text.disabled },
      },
      categories: props.categories,
    },
  };

  return (
    <ReactApexcharts
      type="line"
      options={options}
      series={props.series}
      height={props.height}
    />
  );
};

export { LineChart };
