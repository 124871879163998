// ** Type import
import DashboardIcon from "@mui/icons-material/Dashboard";
import React from "react";
import LayersIcon from "@mui/icons-material/Layers";
import InputIcon from "@mui/icons-material/Input";
import { ROUTES } from "../routes/routes-list";

const navigation = (): any => {
  return [
    {
      title: "Dashboard",
      icon: <DashboardIcon />,
      path: ROUTES.DASHBOARD,
    },
    {
      title: "Matches",
      icon: <LayersIcon />,
      path: ROUTES.MATCHES,
    },
    {
      title: "Workload Inputs",
      icon: <InputIcon />,
      path: ROUTES.DRIVER,
    },
  ];
};

export default navigation;
