import * as React from "react";
import Grid from "@mui/material/Grid";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { ExtendedThemeOptions } from "../../layouts/types";
import { hexToRGBA } from "../../utils/hex-to-rgba";
import { thresholds } from "../../utils/color-threshold";

interface IOnTrack {
  onTrack?: boolean;
  percentage?: number;
}

export function ProgressTrack(props: IOnTrack) {
  const { onTrack, percentage } = props;
  const theme = useTheme() as ExtendedThemeOptions;
  const backgroundColor = onTrack ? thresholds(90, 1) : thresholds(50, 1);

  return (
    <React.Fragment>
      <ProgressBarContainer percentage={percentage}>
        {percentage !== undefined && (
          <TitlePercentage>{percentage}%</TitlePercentage>
        )}
        <OnTrackContainer
          sx={{
            backgroundColor: backgroundColor,
          }}
        >
          <CircleResult>
            {onTrack ? (
              <CheckIcon
                sx={{
                  color: `${hexToRGBA(theme.palette.primary.contrastText, 1)}`,
                }}
              />
            ) : (
              <PriorityHighIcon
                sx={{
                  color: `${hexToRGBA(theme.palette.primary.contrastText, 1)}`,
                }}
              />
            )}
          </CircleResult>
          <Title>{onTrack ? "ON TRACK" : "BEHIND"}</Title>
        </OnTrackContainer>
      </ProgressBarContainer>
    </React.Fragment>
  );
}

type ProgressBarContainerProps = {
  percentage?: number;
};

const ProgressBarContainer = styled(Grid)<ProgressBarContainerProps>(
  ({ theme, percentage }) => ({
    fontSize: 12,
    fontWeight: "bold",
    width: percentage !== undefined ? "180px" : "120px",
    borderRadius: 16,
    backgroundColor: `${hexToRGBA(theme.palette.grey[400], 1)}`,
    height: 32,
    display: "flex",
    alignItems: "center",
    position: "relative",
  })
);

const OnTrackContainer = styled(Box)(({ theme }: any) => ({
  backgroundColor: `${hexToRGBA(theme.palette.primary.main, 0.7)}`,
  display: "flex",
  alignItems: "center",
  height: 32,
  width: "100%",
  borderRadius: 16,
}));

const CircleResult = styled(Box)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: `${hexToRGBA(theme.palette.primary.main, 1)}`,
  height: "100%",
  borderRadius: "100%",
  width: 33,
}));

const TitlePercentage = styled(Box)(({ theme }: any) => ({
  paddingLeft: 10,
  fontSize: 18,
  fontWeight: "bold",
  paddingRight: 20,
  display: "flex",
  color: `${hexToRGBA(theme.palette.primary.contrastText, 1)}`,
}));

const Title = styled(Box)(({ theme }: any) => ({
  paddingLeft: 5,
  paddingRight: 5,
  color: `${hexToRGBA(theme.palette.primary.contrastText, 1)}`,
}));
