// ** MUI Imports
import React, { useMemo, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { createGenericContext } from "../../../../utils";
import { HeaderContextInterface } from "../models";
import { useGetProfile } from "../use-get-profile";
import { searchParams as ISearchParams } from "../models";
import { useAuth0 } from "@auth0/auth0-react";
// ** Type Import
import { Settings } from "../../../../core/context/settingsContext";

// ** Hook Import
import { useSettings } from "../../../../core/hooks/useSettings";

const [useHeader, HeaderContextProvider] =
  createGenericContext<HeaderContextInterface | undefined>();

const HeaderProvider = (props: any) => {
  const { user } = useAuth0();
  const { data } = useGetProfile(user!.cmUserId);
  const [searchParams, setSearchParams] = useSearchParams();
  const weekNumber = searchParams.get("weekNumber") || "";
  const yearNumber = searchParams.get("year") || "";
  const quarter = searchParams.get("quarter") || "";
  const locationId = searchParams.get("locationId") || "";
  const departmentId = searchParams.get("departmentId") || "";
  const { settings, saveSettings } = useSettings();

  const [weekDate, setWeekDate] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );

  const locations = useMemo(() => {
    return data?.locations;
  }, [data?.locations]);

  useEffect(() => {
    if (!yearNumber && data) {
      handleLocationChange(data?.locationId || "all");
    }
  }, [data]);

  useEffect(() => {
    if (!locationId && data?.locationId) {
      handleLocationChange(data.locationId || "all");
    }
  }, [data?.locationId]);

  useEffect(() => {
    if (data?.theme?.color) {
      handleChangeThemeColor(
        "themeColor",
        "user",
        data?.theme.color?.primary,
        data?.theme.color?.rga,
        data?.theme.color?.charts
      );
    }
  }, [data?.theme]);

  const handleChangeThemeColor = (
    field: keyof Settings,
    value: Settings[keyof Settings],
    colorCombination: any,
    rga: any,
    charts: any
  ): void => {
    saveSettings({
      ...settings,
      [field]: value,
      colorCombination,
      rga: rga,
      charts: charts,
      userFontFamily: data?.theme?.fontFamily,
    });
  };

  const departments = useMemo(() => {
    if (!locationId) {
      return [];
    }

    let departments = data?.locations.filter((s) => s.id == locationId)[0]
      ?.departments;

    if (
      departments &&
      departments[0].name != "Whole Store" &&
      departments.length > 1
    )
      departments?.unshift({ id: "", name: "Whole Store" });

    return departments ?? [];
  }, [locationId, data?.locations]);

  const weeklyPeriods = useMemo(() => {
    return data?.weeklyPeriods;
  }, [data?.weeklyPeriods]);

  const handleDateChange = (newValue: Dayjs | null) => {
    const newDate = dayjs(newValue).toString();
    setWeekDate(newValue);
    handleWeekUpdate(newDate);
  };

  useEffect(() => {
    if (!weekNumber && weeklyPeriods && weeklyPeriods.length) {
      //const newDate = dayjs(new Date()).toString();
      handleWeekUpdate("2023-04-10");
    }
  }, [weeklyPeriods]);

  const handleWeekUpdate = (newDate: string) => {
    const date = moment(new Date(newDate)).format("YYYY-MM-DD");
    weeklyPeriods?.map((week) => {
      let isWeek = moment(date).isBetween(week.startDate, week.endDate); // true
      if (!isWeek && (date === week.startDate || date === week.endDate)) {
        isWeek = true;
      }
      if (isWeek) handleWeekChange(week.weekNumber);
    });
  };

  const handleSearch = ({
    locationId,
    departmentId,
    weekNumber,
    quarter,
    yearNumber,
  }: ISearchParams) => {
    setSearchParams({
      locationId: data?.locationId || locationId,
      departmentId: data?.departmentId || departmentId,
      weekNumber: weekNumber,
      quarter: quarter,
      year: yearNumber,
    });
  };

  const handleWeekChange = (weekNumber: string) => {
    let period = weeklyPeriods!.filter((s) => s.weekNumber == weekNumber)[0];
    handleSearch({
      weekNumber: period.weekNumber,
      locationId: locationId || "all",
      departmentId,
      quarter: period.quarter,
      yearNumber: period.year,
    });
  };

  const handleQuarterChange = (quarter: string) => {
    let period = weeklyPeriods!.filter((s) => s.quarter == quarter)[0];
    console.log(period);
    handleSearch({
      weekNumber: period.weekNumber,
      locationId: locationId || "all",
      departmentId,
      quarter: period.quarter,
      yearNumber: yearNumber,
    });
  };

  const handleDepartmentChange = (id: string) => {
    handleSearch({
      weekNumber,
      locationId,
      departmentId: id,
      quarter,
      yearNumber,
    });
  };

  const handleLocationChange = (id: string) => {
    handleSearch({
      weekNumber: weekNumber || "1",
      locationId: data?.locationId || id,
      departmentId: "",
      quarter: quarter || "1",
      yearNumber: yearNumber || "2023",
    });
  };

  const contextValue = {
    locations,
    departments,
    weeklyPeriods,
    weekNumber,
    locationId,
    departmentId,
    weekDate,
    handleDateChange,
    handleWeekChange,
    handleDepartmentChange,
    handleLocationChange,
    handleQuarterChange,
    quarter,
  };

  return (
    <HeaderContextProvider value={contextValue}>
      {props.children}
    </HeaderContextProvider>
  );
};

export { HeaderProvider, useHeader };
