import React from "react";
import Routes from "./navigation/routes";
import { Auth0Provider } from "@auth0/auth0-react";

const App = () => (
  <React.Suspense>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID!}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: "openid profile email offline_access",
      }}
    >
      {Routes.useRenderRoutes()}
    </Auth0Provider>
  </React.Suspense>
);

export default App;
