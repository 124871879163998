import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Box, Button, TextField, FormControl, useTheme } from "@mui/material";
import {
  RGAColorContrast,
  ThemeColorContrast,
} from "../../../core/layouts/types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSettings } from "../../../core/hooks/useSettings";
import { Settings } from "../../../core/context/settingsContext";
import { ExtendedThemeOptions } from "../../../core/layouts/types";

type FormValues = {
  primary: ThemeColorContrast;
  rga: RGAColorContrast;
};

const codeRegex = /^#[a-zA-Z0-9]+$/;

const schema = yup.object().shape({
  primary: yup.object().shape({
    main: yup
      .string()
      .required("Main color is required")
      .matches(codeRegex, "Color must be a valid hex color code"),
    dark: yup
      .string()
      .required("Dark color is required")
      .matches(codeRegex, "Color must be a valid hex color code"),
    light: yup
      .string()
      .required("Light color is required")
      .matches(codeRegex, "Color must be a valid hex color code"),
    contrastText: yup
      .string()
      .required("Contrast color is required")
      .matches(codeRegex, "Color must be a valid hex color code"),
  }),
  rga: yup.object().shape({
    amber: yup
      .string()
      .required("Amber color is required")
      .matches(codeRegex, "Color must be a valid hex color code"),
    green: yup
      .string()
      .required("green color is required")
      .matches(codeRegex, "Color must be a valid hex color code"),
    red: yup
      .string()
      .required("Red color is required")
      .matches(codeRegex, "Color must be a valid hex color code"),
  }),
});

const ColorForm = ({ handleClose }: any) => {
  const theme = useTheme() as ExtendedThemeOptions;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      primary: theme.palette?.primary,
      rga: theme.palette?.rga,
    },
  });
  const { settings, saveSettings } = useSettings();

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    handleChangeThemeColor("themeColor", "user", data?.primary, data?.rga);
  };

  const handleChangeThemeColor = (
    field: keyof Settings,
    value: Settings[keyof Settings],
    colorCombination: any,
    rga: any
  ): void => {
    saveSettings({
      ...settings,
      [field]: value,
      colorCombination,
      rga: rga,
    });
  };

  return (
    <Box width={"100%"} marginY={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          variant="outlined"
          fullWidth
          sx={{ "& > :not(style)": { m: 1 } }}
        >
          <TextField
            id="primary-main"
            label="Primary Main"
            {...register("primary.main", { required: true })}
            fullWidth
          />
          {errors.primary?.main && <span>{errors.primary?.main?.message}</span>}

          <TextField
            id="primary-dark"
            label="Primary Dark"
            {...register("primary.dark", { required: true })}
            fullWidth
          />
          {errors.primary?.dark && <span>{errors.primary?.dark?.message}</span>}

          <TextField
            id="primary-light"
            label="Primary Light"
            {...register("primary.light", { required: true })}
            fullWidth
          />
          {errors.primary?.light && (
            <span>{errors.primary?.light?.message}</span>
          )}

          <TextField
            id="primary-contrast-text"
            label="Primary Contrast Text"
            {...register("primary.contrastText", { required: true })}
            fullWidth
          />
          {errors.primary?.contrastText && (
            <span>{errors.primary?.contrastText?.message}</span>
          )}

          <TextField
            id="rga-green"
            label="RGA Green"
            {...register("rga.green", { required: true })}
            fullWidth
          />
          {errors.rga?.green && <span>{errors.rga?.green?.message}</span>}

          <TextField
            id="rga-amber"
            label="RGA Amber"
            {...register("rga.amber", { required: true })}
            fullWidth
          />
          {errors.rga?.amber && <span>{errors.rga?.amber?.message}</span>}

          <TextField
            id="rga-red"
            label="RGA red"
            {...register("rga.red", { required: true })}
            fullWidth
          />
          {errors.rga?.red && <span>{errors.rga?.red?.message}</span>}

          <Box display={"flex"} justifyContent="center">
            <Button type="submit" variant="contained" color="primary">
              Apply
            </Button>
          </Box>
        </FormControl>
      </form>
    </Box>
  );
};

export { ColorForm };
