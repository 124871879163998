import * as React from "react";
import {
  MenuItem,
  Menu as MaterialMenu,
  MenuProps,
  alpha,
  useTheme,
} from "@mui/material";
import styled from "styled-components";

interface SideBarProps extends MenuProps {
  menuOptions: MenuOption[];
  handleCloseMenu: () => void;
  handleSelectMenu: (name: string) => void;
  anchorEl: any;
}

interface MenuOption {
  name: string;
  value: string;
}
export const Menu = (props: SideBarProps) => {
  const { menuOptions, handleCloseMenu, handleSelectMenu } = props;
  return (
    <StyledMenu
      id="demo-customized-menu"
      MenuListProps={{
        "aria-labelledby": "demo-customized-button",
      }}
      onClose={handleCloseMenu}
      {...props}
    >
      {menuOptions.map((menu, index) => {
        return (
          <MenuItem
            key={index}
            disableRipple
            onClick={() => handleSelectMenu(menu.value)}
          >
            {menu.name}
          </MenuItem>
        );
      })}
    </StyledMenu>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <MaterialMenu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => {
  const { spacing, palette } = useTheme();
  return {
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: spacing(1),
      minWidth: 180,
      color: palette.mode === "light" ? "rgb(55, 65, 81)" : palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: palette.text.secondary,
          marginRight: spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            palette.primary.main,
            palette.action.selectedOpacity
          ),
        },
      },
    },
  };
});
