import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import { styled } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { grey } from "@mui/material/colors";

interface MessageCardProps {
  message: string;
  isHideClose?: boolean;
}

export function MessageCard(props: MessageCardProps) {
  const { message, isHideClose } = props;
  const [isDismissed, setDismissed] = useState(false);

  if (isDismissed) return <></>;

  return (
    <React.Fragment>
      <Grid mt={3}>
        <CardContainer>
          <ErrorIcon sx={{ color: grey[300] }} />
          <Typography component="p" variant="subtitle2" pl={2}>
            {message}
          </Typography>
          <Typography component="p" />
          {!isHideClose && (
            <CancelIcon
              sx={{ color: grey[400] }}
              onClick={() => {
                setDismissed(true);
              }}
            />
          )}
        </CardContainer>
      </Grid>
    </React.Fragment>
  );
}

const CardContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  padding: "1rem",
  margin: "2rem 1rem",
}));
