// ** MUI Imports
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHeader } from "./context/context";
import { HeaderContextInterface } from "./models";
import { ClearMatchToolTip } from "../../../core/components/clearmatch-tooltip";

const WeeklyPeriod = () => {
  const { weeklyPeriods, weekNumber, handleWeekChange } =
    useHeader() as HeaderContextInterface;

  const handleChange = (event: SelectChangeEvent) => {
    handleWeekChange(event.target.value);
  };

  const handleChangeArrow = (arrow: string) => {
    let newWeekNumber: string | undefined = "";
    if (arrow === "forward") {
      newWeekNumber = weekNumber && (parseInt(weekNumber) + 1)?.toString();
    }
    if (arrow === "backward") {
      newWeekNumber = weekNumber && (parseInt(weekNumber) - 1)?.toString();
    }
    if (newWeekNumber) {
      const week = (weeklyPeriods || []).find(
        (week) => week.weekNumber == newWeekNumber
      );
      week && handleWeekChange(newWeekNumber);
    }
  };

  return (
    <Box display={"flex"} alignItems="center" justifyContent={"center"}>
      <ClearMatchToolTip message={"Week backward"}>
        <ArrowBackIosNewIcon
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => handleChangeArrow("backward")}
        />
      </ClearMatchToolTip>
      <FormControl sx={{ minWidth: 100 }} size="small">
        <Select
          id="week-select-helper"
          inputProps={{ "aria-label": "Without label" }}
          value={weeklyPeriods ? weekNumber : ""}
          onChange={handleChange}
        >
          {(weeklyPeriods || []).map((week) => (
            <MenuItem key={week.weekNumber} value={week.weekNumber}>
              Week {week.weekNumber}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ClearMatchToolTip message={"Week forward"}>
        <ArrowForwardIosIcon
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => handleChangeArrow("forward")}
        />
      </ClearMatchToolTip>
    </Box>
  );
};

export default WeeklyPeriod;
