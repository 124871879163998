import * as React from "react";
import Button from "@mui/material/Button";

interface IButtonProps {
  name: string;
  endIcon?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export function SwlButton(props: IButtonProps) {
  const { name, endIcon, onClick } = props;
  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={onClick}
      endIcon={endIcon ?? ""}
    >
      {name}
    </Button>
  );
}
