import * as React from "react";
import { Grid, Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import { hexToRGBA } from "../../utils/hex-to-rgba";
import { useNavigate } from "react-router-dom";
import { Widgets } from "@mui/icons-material";

interface IProgressBar {
  covered?: number;
  total?: number | string;
  titleText?: string;
  startPoint?: number;
  completed?: boolean;
  isPercentage?: boolean;
  coveredProgressBar?: number;
  isLast?: number;
  bgColor?: string;
  height?: number;
  margin?: number;
  progressTag?: React.ReactNode;
  containerBgColor?: string;
  tooltipText?: React.ReactNode;
  href?: string;
}

export function SwlProgressBar(props: IProgressBar) {
  const {
    covered,
    total,
    titleText,
    startPoint,
    completed,
    isPercentage,
    coveredProgressBar = 0,
    isLast,
    bgColor,
    height,
    margin,
    progressTag,
    containerBgColor,
    tooltipText: tooltipText,
    href,
  } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <React.Fragment>
      <ProgressBarContainer
        height={height}
        margin={margin}
        backgroundColor={containerBgColor}
      >
        <ProgressLink
          href={href}
          onClick={(e) => {
            e.preventDefault();

            if (href) navigate(href);
          }}
        >
          <GridContainer
            percentage={coveredProgressBar}
            background={bgColor}
          ></GridContainer>
          {covered && (
            <PercentageCover percentage={covered}>
              <Box display={"flex"} alignItems="center">
                {covered} {isPercentage && "%"}
              </Box>
            </PercentageCover>
          )}
          {progressTag && (
            <ProgressTag percentage={coveredProgressBar}>
              {progressTag}
            </ProgressTag>
          )}
          {titleText && (
            <Title
              left={
                height
                  ? height + 5
                  : startPoint != undefined || tooltipText
                  ? 40
                  : 15
              }
              top={(height && height / 2 - 9) || 7}
            >
              {titleText}
            </Title>
          )}
          <Box position={"absolute"} top={0} height={"100%"}>
            {startPoint != undefined && (
              <CircleResult height={height}>{startPoint}</CircleResult>
            )}
          </Box>

          <Box
            position={"absolute"}
            left={height ? height + 5 : 13}
            top={(height && height / 2 - 5) || 4}
            height={"100%"}
          >
            {tooltipText && tooltipText}
          </Box>

          <FinalResult>
            {completed ? (
              <CircleResult height={height}>
                <CheckIcon
                  sx={{
                    color: `${hexToRGBA(
                      theme.palette.primary.contrastText,
                      1
                    )}`,
                  }}
                />
              </CircleResult>
            ) : isLast !== undefined ? (
              <CircleResult height={height}>{isLast}</CircleResult>
            ) : (
              <Total>
                {total?.toLocaleString()} {isPercentage && "%"}
              </Total>
            )}
          </FinalResult>
        </ProgressLink>
      </ProgressBarContainer>
    </React.Fragment>
  );
}

type StyleProps = {
  height?: number;
  margin?: number;
  backgroundColor?: string;
};

type ProgressTagProps = {
  percentage: number;
};

const ProgressLink = styled("a")(({ theme }: any) => ({
  color: `${hexToRGBA(theme.palette.primary.contrastText, 1)}`,
}));

const ProgressBarContainer = styled(Grid)<StyleProps>(
  ({ theme, height, margin, backgroundColor }) => ({
    position: "relative",
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    borderRadius: 33,
    background:
      backgroundColor && backgroundColor !== ""
        ? backgroundColor
        : `${hexToRGBA(theme.palette.grey[400], 1)}`,
    height: height ?? 32,
    marginTop: margin ?? "2rem",
    marginBottom: margin ?? "2rem",
    color: `${hexToRGBA(theme.palette.primary.contrastText, 1)}`,
  })
);

type GridContainerProps = {
  percentage: number;
  background?: string;
};

const GridContainer = styled(Grid)<GridContainerProps>(
  ({ theme, percentage, background }) => ({
    background:
      background && background !== ""
        ? background
        : `${hexToRGBA(theme.palette.primary.light, 1)}` ||
          "linear-gradient(45deg," +
            `${hexToRGBA(theme.palette.primary.main, 0.9)}` +
            " 30%, " +
            `${hexToRGBA(theme.palette.primary.main, 0.7)}` +
            " 90%)",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    display: "flex",
    alignItems: "center",
    borderRadius: 33,
    width: percentage + "%",
    maxWidth: "100%",
    height: "100%",
    position: "relative",
  })
);

type PercentageCoverProps = {
  percentage: number;
};

const PercentageCover = styled(Grid)<PercentageCoverProps>(
  ({ theme, percentage }: any) => ({
    position: "absolute",
    left: `calc(${percentage + "%"} - 50px)`,
    background: `${hexToRGBA(theme.palette.primary.main, 0.8)}`,
    borderRadius: 33,
    height: "100%",
    display: "flex",
    width: 60,
    color: `${hexToRGBA(theme.palette.primary.contrastText, 1)}`,
    top: 0,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 99,
    [theme.breakpoints.down("md")]: {
      left: percentage > 30 ? percentage - 10 + "%" : percentage,
    },
  })
);

const ProgressTag = styled(Grid)<ProgressTagProps>(
  ({ theme, percentage }: any) => ({
    position: "absolute",
    background: `${hexToRGBA(theme.palette.primary.main, 1)}`,
    borderRadius: 33,
    height: "100%",
    display: "flex",
    right: 100 - Math.max(percentage, 30) + "%",
    padding: 12,
    color: `${hexToRGBA(theme.palette.primary.contrastText, 1)}`,
    zIndex: 100,
    opacity: 0.8,
    top: 0,
  })
);

const FinalResult = styled(Grid)(({ theme }: any) => ({
  position: "absolute",
  right: 0,
  top: 0,
  height: "100%",
  display: "flex",
  alignItems: "center",
}));

const CircleResult = styled(Box)<StyleProps>(({ theme, height }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: `${hexToRGBA(theme.palette.primary.main, 1)}`,
  color: `${hexToRGBA(theme.palette.primary.contrastText, 1)}`,
  height: "100%",
  borderRadius: "100%",
  width: height ?? 33,
}));

const Title = styled(Box)(({ theme }: any) => ({
  position: "absolute",
}));

const Total = styled(Box)(({ theme }: any) => ({
  paddingRight: 10,
}));
