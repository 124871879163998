import { axios } from "./axios";
import type { UserProfile } from "../layouts/components/header/models";
import { DashboardStats, MatchFitData } from "../pages/dashboard/models";

const ENDPOINT_USER_STATS = (
  userId: string,
  year: string,
  weekNumber: number,
  locationId: number,
  departmentId: number
) => {
  let dest = `/users/${userId}/${locationId}/${year}/${weekNumber}`;

  if (departmentId) dest += `/${departmentId}`;

  return `${dest}/dashboard`;
};
const ENDPOINT_USER_PROFILE = (userId: string) => `/users/${userId}/profile`;

export async function getProgress({ queryKey }: any) {
  const [userId, year, weekNumber, locationId, departmentId] = queryKey;

  const { data } = await axios.get<DashboardStats>(
    ENDPOINT_USER_STATS(userId, year, weekNumber, locationId, departmentId)
  );
  return data;
}

export async function getProfile(userId: string) {
  const { data } = await axios.get<UserProfile>(ENDPOINT_USER_PROFILE(userId));
  return data;
}

export async function getMatchFits({ queryKey }: any) {
  const [userId, year, weekNumber] = queryKey;
  const ENDPOINT_USER_MATCH_FITS = `matches/${userId}/report/${year}/${weekNumber}`;

  const { data } = await axios.get<MatchFitData[]>(ENDPOINT_USER_MATCH_FITS);
  return data;
}
